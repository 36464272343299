import React, {useState} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {navigate} from "@reach/router";
import {getCountries} from "../../util/constant";

const NationalCourtsCaseLawList = ({}: any) => {

    const [selectedCountry, setSelectedCountry] = useState('')
    // const [selectedLawType, setSelectedLawType] = useState('All Laws')
    const {t, i18n} = useTranslation()
    const  handleChange = (country: string) => {
        country && setSelectedCountry(country)
        // const query = new URLSearchParams({country: country ? country : selectedCountry })
        const path = `${location.pathname}${country.split(' ').join('-')}/`
        navigate(path);
        // navigate("/app/nationalLaw", { state: { country: country, law: selectedLawType } })
    }
    return (
        <>
            <div className="col-xl-12 order-xl-2">
                <section id="ste" className="wrapper py-10">
                    <h2 className="mb-5">{t('NationalcourtsCaseLaw')}</h2>
                    <h6 className="widget-title fs-17 mb-2 ps-xl-5">{t('Choose an element')}</h6>
                    <div className="widget">
                        <form className="search-form">
                            <div className="form-floating mb-0">
                                <input id="search-form" type="text" className="form-control"
                                       placeholder="Search"/>
                                <label htmlFor="search-form">{t('Search')}</label>
                            </div>
                        </form>
                    </div>
                    <div className="widget shadow-lg p-2">
                        <nav className="ps-xl-5" id="sidebar-nav">
                            <ul className="list-unstyled fs-sm lh-sm text-reset overflow-auto"
                                style={{
                                    // maxWidth: '60px',
                                    maxHeight: '300px',
                                    scrollbarWidth: 'thin',

                                }}>
                                {getCountries(i18n.language)
                                    .sort((a, b) => a.name > b.name ? 1 : -1)
                                    .map((country, id) => {
                                    return (<li key={id} className="pe-auto text-fuchsia pe-auto">
                                        <a
                                            style={{cursor: 'pointer'}}
                                            data-toggle="tooltip" data-placement="top" title={`${t('Ratification Date')} : ${country.ractification}`}
                                            className={`nav-link ${selectedCountry === country.name && 'active'}`}
                                            onClick={()=>handleChange(country.name)}>
                                            {country.name}
                                        </a>
                                    </li>)
                                })}
                            </ul>
                        </nav>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NationalCourtsCaseLawList
