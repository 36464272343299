import React from "react";
import NationalCourtsCaseLawHeader from "./NationalCourtsCaseLawHeader";
import NationalCourtsCaseLawList from "./NationalCourtsCaseLawList";

const NationalCourtsCaseLawContent = ({}: any) => {

    return (
        <>
            <NationalCourtsCaseLawHeader/>

            <div className="container">
                <div className="row">
                    <NationalCourtsCaseLawList />
                </div>
            </div>
        </>
    )
}

export default NationalCourtsCaseLawContent
